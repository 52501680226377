import React from "react"
import "./privacy.css"

const Privacy = () => {
  return (
    <div className="privacy">
      <h3 className="text-primary">Privacy Policy</h3>

      <p>
        Your privacy is important and we understand the details you provide us
        are private and personal and that how we collect, use, hold, exchange
        and protect your information is important to you. It is imperative that
        you feel secure whenever you deal with us. It’s also important that you
        understand how we protect your privacy as well as how, when and where we
        may use your personal information. This Privacy Policy outlines the
        steps we take and you can be confident that we abide and are bound by
        the Privacy Act and the Australian Privacy Principles (APPs) for the
        protection of personal information.
      </p>

      <h6>WHAT INFORMATION DO COLLECT</h6>
      <p>
        We collect personal information. Personal information means any
        information or opinion about you as an identified individual or an
        individual who is reasonably identifiable, whether the information or
        opinion is true or not and whether the information or opinion is
        recorded in a material form or not. For example, identity and contact
        details, marital status and financial information. We may also collect
        sensitive information for specific purposes. For example, information
        about your health to enable the credit provider to assess an application
        you make for mortgage protection insurance and to calcliate any
        premiums. If this information is required, yourconsent will be
        requested.
      </p>

      <h6>HOW WE COLLECT YOUR PERSONAL INFORMATION</h6>
      <p>
        We will always aim to collect your personal information directly from
        you. If for some good reasonwe are unable to do so we may involve
        another organisation. For example, we may need to request a credit
        report from a credit reporting body when you apply for a credit
        facility. Before doing so or prior to collecting the information, we
        will inform you of the type of organisations we intend to disclose your
        personal information to. We may also collect your personal information
        through third parties who undertake the collection on our behalf.
      </p>

      <h6>WHY WE COLLECT YOUR PERSONAL INFORMATION</h6>
      <p>
        We collect and receive personal information directly from you or from
        other business referrers in order to conduct our business so that we
        can:
        <br />
        <br />
        <ul>
          <li>
            submit your application for credit to a supplier via our Aggregator
            partner National Mortgage Brokers Pty Ltd;
          </li>
          <li>
            identify and communicate with you via direct marketing
            communications about your current products and other products or
            services offered by us or a third party that may interest you
            (unless you advise us not to);
          </li>
          <li>administer our relationship with you;</li>
          <li>
            manage internal processes including reporting and analytical
            purposes;
          </li>
          <li>meet our legal and regliatory reporting obligations;</li>
          <li>establish a customer marketing program;</li>
          <li>develop and test systems;</li>
          <li>any other purpose related to the above.</li>
        </ul>
      </p>

      <h6>DIRECT MARKETING</h6>
      <p>
        We may use your information to provide correspondence about products and
        services that may be of interest to you. We may also send you details of
        offers or services provided by our business partners and related
        entities. These direct marketing offers and promotions are designed to
        make your life easier, not harder. You may at any time choose not to receive these communications. Doing so
        will not cost you andwe commit to ensuring any unwanted communications
        are ceased as soon as possible. If you do not want to receive such
        marketing communications please write to us at the following address:{" "}
        <br />
        <br />
        The Privacy Officer <br />
        Compare n Save Pty Ltd <br />
        Level 1 1911 Malvern Road <br />
        Malvern East VIC 3145
      </p>

      <h6>WHY YOUR INFORMATION IS EXCHANGED WITH OTHER ORGANISATIONS</h6>
      <p>
        As a Mortgage Broker we aim to provide you with products and services to
        suit your requirements. That means we partner with other organisations
        who provide and support the products and services we offer. In order to
        submit your application to a supplier for consideration,we will need to
        provide such supplier with your personal information that we hold.
      </p>

      <p>
        In certain circumstances we may be compelled by law to disclose your
        personal information to various authorities. For instance we may be
        required under the Anti-Money Laundering and Counter-Terrorism Financing
        Act to disclose details of financial transactions and we may also be
        compelled under tax laws to provide such information to regulators.
        Other organisations which we may exchange your personal information with
        include:
        <br /> <br />
        <ul>
          <li>credit providers;</li>
          <li>credit reporting bodies;</li>
          <li>government and law enforcement agencies;</li>
          <li>
            our suppliers, associates, affiliates partners, service providers or contractors
            (including, for example, call centre, stationery printing houses,
            mail houses, information technology, marketing agencies, and
            marketing research companies etc.);
          </li>
          <li>
            any person who proposes to guarantee or has guaranteed repayment of
            any credit provided to you;
          </li>
          <li>
            mortgage insurers, brokers, originators, mortgage managers,
            financial consultants, accountants, lawyers or real estate agents;
          </li>
          <li>
            our aggregator partner National Mortgage Brokers Pty Ltd;
          </li>
          <li>
            our affiliate partner Macro Associates Pty Ltd;
          </li>
          <li>
            those in connection with funding financial accommodation by means of
            an arrangement involving securitisation;
          </li>
          <li>
            third parties to enable them to contact you to offer their products
            and services or to determine if you would be interested in the
            products and services; and
          </li>
          <li>
            any person considering acquiring or taking an interest in our
            business.
          </li>
        </ul>
      </p>
      <p>
        This is to your benefit and helps us to provide the products and
        services you would expect from us.
      </p>
      <p>
        Before we disclose any of your personal information to any other
        organisation, reasonable steps will be taken to ensure: the organisation
        has the same commitment as us to the protection of your personal
        information; and you have consented to us doing so. Consent means
        approval in writing, orally, or implied from your dealings with us.
      </p>

      <h6>DISCLOSURE OF PERSONAL INFORMATION OVERSEAS</h6>
      <p>
        We may hold or process your information on servers located overseas for
        filtering, hosting or storage purposes, reporting and analytical
        purposes and for system development testing purposes. If we do this, we
        ensure such organisations have the appropriate data handling and
        security arrangements in place that complies with this Privacy Policy.
        Australia’s Privacy Act may not apply to such overseas organisations.
      </p>

      <h6>ACCESS AND CORRECTION OF YOUR PERSONAL INFORMATION</h6>
      <p>
        It is important for us and you the information we hold is accurate and
        up to date. In this we rely on your assistance. We ask that you contact
        us whenever there are any changes to your personal information, so that
        we can update our records. The personal information we hold aboutyou is
        yours to access and correct, and as such we can provide you with general
        information such as your name, address and contact details quite easily.
        There is no fee for making the initialrequest. More detailed information
        may not be as easy for us to access and in some cases there may be an
        access charge to cover the time we spend locating, compiling and
        explaining the information you request. If an access fee is to be
        charged you will provided an estimate. Generally the access charge is
        based on an hourly rate plus any photocopying and other out-of-pocket
        expenses. Payment is required before we start.
      </p>

      <p>
        The process of providing you with access to or correcting your personal
        information may take up to 30 working days from the date of your
        request. If we refuse to give you access to or correct your information,
        we must provide you written notice setting out the reasons for the
        refusal and the mechanisms available for you to complain about the
        refusal.
      </p>

      <p>
        If you need to access or correct your information, please write to us at
        the following address:
        <br />
        <br />
        The Privacy Officer
        <br />
        Compare n Save Pty Ltd
        <br />
        Level 1 1911 Malvern Road
        <br />
        Malvern East VIC 3145
        <br />
      </p>

      <h6>SECURITY AND STORAGE OF YOUR PERSONAL INFORMATION</h6>
      <p>
        How long do we keep information about you? <br />
        By law, we are required to hold certain information about you even after
        you are no longer a customer. We may also hold certain information about
        you if it is needed for a purpose which youare aware of or reasonably
        expect us to hold the information. When your information is no longer
        needed or required to be held by law, it will be destroyed or
        de-identified.
      </p>

      <p>
        How safe and secure is the information we hold about you?
        <br />
        We will take great care to protect your personal information by storing
        it in a secure environment,and when the information is no longer needed
        for any purpose for which the information may be used or disclosed, it
        will be destroyed or permanently de-identified. We will also take
        reasonable steps to protect any personal information from misuse, loss
        and unauthorized access, modification or disclosure.
      </p>

      <p>
        What are ‘cookies’ and how do they work?
        <br />
        Cookies are small data files that are downloaded from our web servers
        and stored on your hard drive. A cookie is a string of letters and
        numbers that uniquely identify the computer you are using and the
        customer number and access code you may have used to register at the
        site. Cookies are used on the this web site. We track a visitor’s
        journey through our site. This allows us to see at a glance which pages
        and information are of most interest to visitors. This type of cookie
        contains no personal information; simply a record of your journey
        through the site. Most browsers can be configured to refuse to accept
        cookies. You can also delete cookies form your hard drive however doing
        so may hinder your access to valuable areas of information within our
        site.
      </p>

      <h6>IF YOU HAVE A PRIVACY COMPLAINT</h6>
      <p>
        We value our customers. We will always aim to be fair and responsive. If
        you have a complaint including a complaint about our compliance with the
        APPs you have the right to expect that we will handle it in a friendly
        and professional way. If we receive a complaint we look at it as
        valuable feedback that may help us to improve the services we offer and
        to ensure your needs are met in a satisfactory and appropriate manner.
        If you wish to complain at any time about the handling, use or
        disclosure of your personal information, or about our compliance with
        the APPs, please write to us at the following address:
        <br /> <br />
        The Privacy Officer
        <br />
        Compare n Save Pty Ltd
        <br />
        Level 1 1911 Malvern Road
        <br />
        Malvern East VIC 3145
        <br />
        <br />
        We will make all efforts possible to investigate your complaint and
        advise you of the outcome as soon as possible. If the matter is not
        resolved to your satisfaction, you may refer your complaint to the
        Australian Information Commissioner, who can be contacted at:
        <br />
        <br />
        Office of the Australian Information Commissioner (OAIC) <br />
        Level 3 175 Pitt Street
        <br />
        Sydney NSW 2000
        <br />
      </p>

      <h6>MORE INFORMATION</h6>
      <p>
        You can request further information about the way we manage the personal
        information that we hold by writing to:
        <br />
        <br />
        The Privacy Officer
        <br />
        Compare n Save Pty Ltd
        <br />
        Level 1 1911 Malvern Road
        <br />
        Malvern East VIC 3145
      </p>
    </div>
  )
}

export default Privacy
